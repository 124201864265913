import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@atoms/Button";
import HeadingNew from "@atoms/HeadingNew";
import TextNew from "@atoms/TextNew";

const AccordionItem = ({
    accordionClass = "bg-white shadow-[0_2px_4px_rgba(0,0,0,0.15)] p-6 rounded-xl mb-4 overflow-hidden",
    index,
    themeColor = "orange-900",
    title,
    titleClass,
    content,
    contentClass,
    isActive,
    toggleAccordion,
}) => {
    return (
        <div className={`${accordionClass} `} onClick={() => toggleAccordion(index)} style={{ backgroundColor: `${isActive ? "#EF985E" : "#FFF"} ` }}>
            <div
                className={`relative ${titleClass} cursor-pointer`}
                
            >
                <HeadingNew type="h5" fontSize="text-base lg:text-xl" fontWeight="font-semibold" className={`w-full pr-4 text-${isActive ? "white" : themeColor}`}>
                    {title}
                </HeadingNew>
                <div
                    className={` absolute right-0 top-1/2 -translate-y-1/2 w-0 h-0 border-${isActive ? "white" : themeColor} border-t-[6px] border-t-transparent border-l-[10px]  border-b-[6px] border-b-transparent  transform transition-transform ${isActive ? "-rotate-90" : "rotate-0"
                        }`}
                ></div>
            </div>
            <TextNew
                type="body"
                className={`${contentClass}  overflow-hidden ${isActive ? "text-white max-h-[1000px] mt-4 leading-6 !font-semibold" : "text-neutral-60 max-h-0"
                    }`}
            >
                {content}
            </TextNew>
        </div>
    );
};

AccordionItem.propTypes = {
    index: PropTypes.number,
    accordionClass: PropTypes.string,
    title: PropTypes.string,
    titleClass: PropTypes.string,
    themeColor: PropTypes.string,
    content: PropTypes.string,
    contentClass: PropTypes.string,
    isActive: PropTypes.bool,
    toggleAccordion: PropTypes.func,
};

const Accordion = ({
    accordionClass,
    titleClass,
    themeColor,
    contentClass,
    accordionList,
}) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const toggleAccordion = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };


    const [expanded, setExpanded] = useState(false);

    const toggleMoreAccordion = () => {
        setExpanded(!expanded);
    };

    return (
        <div className="relative">
            {accordionList?.slice(0, expanded ? accordionList.length : 5).map((list, index) => (
                <AccordionItem
                    key={index}
                    index={index}
                    accordionClass={accordionClass}
                    themeColor={themeColor}
                    title={list.title}
                    titleClass={titleClass}
                    content={list.content}
                    contentClass={contentClass}
                    isActive={index === activeIndex}
                    toggleAccordion={toggleAccordion}
                />
            ))}
             {accordionList.length > 5 && (
                <div className="text-center mt-4">
                    <Button variant="primaryOutline" btnClass="mx-auto my-8 border-secondary-700 text-secondary-700" width="md:w-[447px] w-[220px]" onClick={toggleMoreAccordion}>{expanded ? "View less" : "View more"} questions </Button>
                </div>
            )}
        </div>
    );
};

Accordion.propTypes = {
    accordionClass: PropTypes.string,
    titleClass: PropTypes.string,
    themeColor: PropTypes.string,
    contentClass: PropTypes.string,
    accordionList: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            content: PropTypes.string.isRequired,
        }).isRequired
    ).isRequired,
};

export default Accordion;
