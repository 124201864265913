import React, { useEffect, useRef } from "react";
import CircleType from "circletype";

const CurvedText = ({ className, text, radius, dir }) => {
    const textRef = useRef(null);

    useEffect(() => {
        const circleType = new CircleType(textRef.current);
        circleType.radius(radius).dir(dir);
    }, [radius, dir]);

    return (
        <div ref={textRef} className={`curvedText ${className}`}>
            {text}
        </div>
    );
};

export default CurvedText;
