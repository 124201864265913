import Heading from "@atoms/Heading";
import CurvedText from "@atoms/CurvedText";
import Text from "@atoms/Text";
import Image from "next/image";
import Link from "next/link";
import React from "react";

const Therapist = ({ id, designation, profileImg, name,textColor }) => {
    return (
        <div className="px-3">
            <Link
                href={`${process.env.NEXT_PUBLIC_MAIN_URL}therapist-detail/${id}`}
            >
                <a target="_blank">
                    <div className=" cursor-pointer flex flex-col justify-center">
                        <CurvedText
                            className={`${textColor} font-bold h-11 `}
                            text={designation}
                            radius={220}
                            dir={1}
                        />

                        <div className="relative h-[270px] w-[270px] rounded-full m-auto">
                            <Image
                                src={profileImg}
                                alt={name || "Picture of the author"}
                                layout="fill"
                                objectFit="cover"
                                objectPosition="top"
                                className="rounded-full"
                            />
                        </div>
                        <div className="font-Nunito font-bold text-base lg:text-xl">
                            <CurvedText
                                className="text-black pt-1 font-bold h-13"
                                text={name}
                                radius={200}
                                dir={-1}
                            />
                        </div>
                    </div>
                </a>
            </Link>
        </div>
    );
};

export default Therapist;
