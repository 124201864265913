import * as React from "react";
import UniqueSunshine from "./UniqueSunshine";
import Image from "next/image";
import HeadingNew from "@atoms/HeadingNew";

const SunshineUnique = ({ UniqueSunshineData }) => {
    return (
        <section className="relative bg-white lg:bg-secondary-800 overflow-hidden">
            <div className="w-11/12 md:max-w-[1193px] mx-auto ">
                <div className="relative">
                    <HeadingNew
                        type="h2"
                        fontSize="text-xl lg:text-[40px]"
                        fontWeight="font-semibold "
                        className=" leading-normal py-8 lg:pt-[86px] lg:pb-[68px]  text-center  "
                    >
                        What Makes Sunshine by LISSUN Unique
                    </HeadingNew>
                    {/* <div className=" absolute hidden md:block right-28 top-12 ">
                        <Image
                            src="/assets/images/sunshine/vector-butterfly-right.png"
                            width={55}
                            height={56}
                        />
                    </div>
                    <div className=" absolute left-[22%] -top-7 md:-top-16 max-w-[35px] md:max-w-auto">
                        <Image
                            src="/assets/images/sunshine/vector-butterfly-left.png"
                            width={55}
                            height={56}
                        />
                    </div> */}
                </div>
                <div>
                    <div className="flex  gap-4 md:gap-16 items-center justify-center mt-8 md:mt-20 md:pb-40 ">
                        <div>
                            {UniqueSunshineData.map((obj, index) => {
                                return (
                                    <UniqueSunshine
                                        key={obj.title}
                                        index={index}
                                        imageUrl1={obj.imageUrl1}
                                        title={obj.title}
                                        sentence1={obj.sentence1}
                                        sentence2={obj.sentence2}
                                        sentence3={obj.sentence3}
                                        mainImage={obj.mainImage}
                                    />
                                );
                            })}
                            ,
                        </div>
                    </div>
                </div>
            </div>


            {/* <div className=" absolute hidden lg:block right-[628px] top-[1278px] ">
                <Image
                    src="/assets/images/sunshine/vector-butterfly-left.png"
                    width={55}
                    height={56}
                />
            </div> */}
            <div className=" absolute hidden md:block right-0 top-80  max-w-30 xl:max-w-[170px] ">
                <Image
                    src="/assets/images/sunshine/vector-rabbit-right.png"
                    width={102}
                    height={189}
                />
            </div>
            <div className=" absolute hidden md:block -left-4 xl:top-[700px] md:top-[600px]  max-w-19 xl:max-w-[150px] ">
                <Image
                    src="/assets/images/sunshine/vector-elephant-left.png"
                    width={150}
                    height={171}
                />
            </div>
            <div className="static md:absolute left-0 bottom-0 -mb-2">
                <Image
                    src="/assets/images/sunshine/banner44.svg"
                    width={1295}
                    height={110}
                />
            </div>
            <div className=" absolute hidden md:block -right-10 bottom-60  max-w-19 xl:max-w-[150px]">
                <Image
                    src="/assets/images/sunshine/vector-jiraf-right.png"
                    width={188}
                    height={335}
                />
            </div>
        </section>
    );
};

export default SunshineUnique;
