import HeadingNew from "@atoms/HeadingNew";
import Image from "next/image";
import React from "react";

const ProfessionalList = ({ imageSrcSet, title, description, viewMorelink }) => {
    return (
        <div className="lg:items-center lg:justify-center items-center  shadow-2xl bg-white flex flex-row lg:flex-col px-3 md:px-6 py-3 lg:pt-16 lg:pb-10 rounded-xl z-20">
            <div className="hidden lg:block w-fit">
                <Image
                    width={160}
                    height={160}
                    loading="lazy"
                    src={imageSrcSet}
                    className="aspect-square object-contain object-center w-full justify-center items-center overflow-hidden"
                />
            </div>
            <div className="lg:hidden block w-1/4 sm:w-fit flex-none">
                <Image
                    width={93}
                    height={93}
                    loading="lazy"
                    src={imageSrcSet}
                    className="aspect-square object-contain object-center w-full overflow-hidden"
                />
            </div>
            <div className="ml-4 lg:ml-0 w-full mx-auto lg:w-full ">
                <HeadingNew
                    type="h4"
                    fontSize="text-sm md:text-base lg:text-2xl"
                    fontWeight="font-semibold"
                    className="overflow-hidden text-left lg:text-center "
                >
                    {title}
                </HeadingNew>
                <div className="text-[#777777] h-fit lg:h-32 !font-Nunito text-left lg:text-center text-xs md:text-base leading-4 md:leading-6  mt-2 lg:mt-8 ">
                    {description}
                </div>
                <div className="text-secondary-700 !font-Nunito text-left lg:text-center text-[10px] md:text-base leading-5 md:leading-6  mt-2 lg:mt-4 underline ">
                    <a href={viewMorelink} target="_blank" rel="noopener noreferrer">
                        View More   
                    </a>
                </div>
            </div>
        </div>
    );
};

export default ProfessionalList;
