import Container from "@atoms/Container";
import * as React from "react";
import CentersCard from "../molecules/CentersCard";
import Image from "next/image";
import BookingForm from "./BookingForm";
import HeadingNew from "@atoms/HeadingNew";
import Carousel from "@molecules/Carousel";

const CentersSection = ({ centersList, onClose }) => {
  return (
    <section
      className="relative text-center bg-secondary-800  overflow-hidden"
      id="centerSection"
    >
      <Container type="type12" className="px-1 md:px-6">
        <div className="absolute xl:left-[220px] left-32 hidden md:block">
          <Image
            src="/assets/images/sunshine/vector-butterfly-left.png"
            width={50}
            height={51}
          />
        </div>

        <HeadingNew
          type="h2"
          fontSize="text-xl lg:text-[40px]"
          fontWeight="font-semibold "
          className="w-full mx-auto leading-normal text-center max-w-[772px] pt-8 lg:pt-[86px] lg:pb-[28px] "
        >
          Explore Our Centers Across India
        </HeadingNew>

        <div className="flex lg:flex-row flex-col lg:items-center lg:justify-center z-10 gap-x-[30px] lg:gap-x-[50px] py-0 md:py-10  ">
          <div className="w-full hidden md:grid lg:w-3/5 grid-cols-1 gap-4 h-full">
            <Carousel
              vertical={true}
              slidesToShow={3}
              dots={false}
              arrows={true}
              autoPlay={false}
              speed={2500}
              iconBg="bg-trasparent"
              arrowPosition="verticalCenter"
              prevIconClass="relative left-0 xl:-left-14"
              nextIconClass="relative right-0 xl:-right-14"
              leftArrowIcon={
                <Image
                  src="/assets/images/sunshine/leftArrow.svg"
                  width={64}
                  height={64}
                  alt=""
                />
              }
              rightArrowIcon={
                <Image
                  src="/assets/images/sunshine/rightArrow.svg"
                  width={64}
                  height={64}
                  alt=""
                />
              }
              responsive={[
                {
                  breakpoint: 2000,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                  },
                },
              ]}
            >
              {centersList?.map((list) => (
                <CentersCard
                  key={list.location}
                  locationImg={list.image}
                  location={list.location}
                  address={list.address}
                  direction={list.direction}
                  link={list.link}
                  phone={list.phone}
                />
              ))}
            </Carousel>
          </div>

          <div className="centers-slider block md:hidden">
            <Carousel
              slidesToShow={1}
              dots={false}
              arrows={false}
              autoPlay={true}
              speed={1000}
              iconBg="bg-trasparent"
              arrowPosition="topCenter"
              prevIconClass="relative top-0 left-0 xl:-left-14"
              nextIconClass="relative top-0 right-0 xl:-right-14"
              leftArrowIcon={
                <Image
                  src="/assets/images/sunshine/leftArrow.svg"
                  width={64}
                  height={64}
                  alt=""
                />
              }
              rightArrowIcon={
                <Image
                  src="/assets/images/sunshine/rightArrow.svg"
                  width={64}
                  height={64}
                  alt=""
                />
              }
              responsive={[
                {
                  breakpoint: 767,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true,
                  },
                },
                {
                  breakpoint: 2500,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    arrows: true,
                    dots: false,
                  },
                },
              ]}
            >
              {centersList?.map((list) => (
                <CentersCard
                  key={list.location}
                  locationImg={list.image}
                  location={list.location}
                  address={list.address}
                  direction={list.direction}
                  link={list.link}
                  phone={list.phone}
                />
              ))}
            </Carousel>
          </div>

          <div className="w-full lg:w-2/5 relative">
            <div className="absolute md:-right-9 -top-20 lg:-top-[149px] hidden lg:block ">
              <Image
                src="/assets/images/sunshine/banner31.svg"
                width={330}
                height={280}
              />
            </div>
            <div>
              <BookingForm
                className="w-full relative z-10 mt-32"
                onClose={onClose}
              />
              <div className="-mb-2.5 lg:hidden block absolute top-6 right-2">
                <Image
                  src="/assets/images/sunshine/StudentCloud.png"
                  width={111}
                  height={103}
                />
              </div>
            </div>
            <div className="absolute -bottom-[125px] md:bottom-20 -right-7 md:-right-[70px] max-w-[250px] md:max-w-max">
              <Image
                src="/assets/images/sunshine/vector-cloud-butterfly.png"
                width={320}
                height={250}
              />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default CentersSection;
