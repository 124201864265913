import * as React from "react";
import PropTypes from "prop-types";

import Accordion from "./Accordion";
import Button from "@atoms/Button";
import Heading from "@atoms/Heading";
import Container from "@atoms/Container";
import HeadingNew from "@atoms/HeadingNew";

const FaqSection = ({
    data,
    bgColor = "bg-[#F7E59D]",
    sectionPadding = "pt-10 lg:pt-[68px] pb-48 lg:pb-[254px]",
    themeColor = "orange-900",
    onClickOpenChatWidget
}) => {
    return (
        <section className={`${bgColor} ${sectionPadding} z-20`}>
            <Container>
                <HeadingNew
                    type="h2"
                    fontSize="text-xl lg:text-[40px]"
                    fontWeight="font-semibold "
                    className=" leading-normal text-center py-8 lg:pt-[78px] lg:pb-[60px]  "
                >
                   {data?.heading}
                </HeadingNew>
                <Accordion themeColor={themeColor} accordionList={data?.accordionList}></Accordion>
                {/* <div className="flex w-full pt-8 lg:pt-16">
                    <Button
                        value="Submit"
                        className="mx-auto bg-secondary-700  text-white "
                        onClick={onClickOpenChatWidget}
                        rounded="rounded-lg"
                        padding="px-16 py-[18px]"

                    >
                        {"Have more specific questions? Ask us here!"}
                    </Button>
                </div> */}
            </Container>
        </section>
    );
};

FaqSection.propTypes = {
    data: PropTypes.shape({
        heading: PropTypes.string.isRequired,
        accordionList: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string.isRequired,
                content: PropTypes.string.isRequired,
            })
        ).isRequired,
    }).isRequired,
    bgColor: PropTypes.string,
    sectionPadding: PropTypes.string,
    themeColor: PropTypes.string,
};

export default FaqSection;
