import TextNew from "@atoms/TextNew";
import Image from "next/image";
import React, { useState, useEffect, useRef } from "react";
import logoUrl from "/public/assets/images/sunshine/whitelogo.svg";

const DropDown = ({ dropDownlist, dropDownname }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isToggled, setIsToggled] = useState(false);
  const [submenuIndex, setSubmenuIndex] = useState(null);

  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsHovered(false);
        setIsToggled(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleSubmenu = (index) => {
    setSubmenuIndex(submenuIndex === index ? null : index);
  };

  return (
    <div
      ref={ref}
      className="relative inline-block text-left dropdown-container"
    >
      <TextNew
        variant="body"
        fontSize="text-xs md:text-base"
        fontWeight="!font-medium"
        fontFamily="font-Nunito"
        className="text-white focus:outline-none hover-underline no-underline"
      >
        <div
          onClick={() => setIsToggled(!isToggled)}
          className="flex items-center gap-2 cursor-pointer no-underline"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {dropDownname}
          <Image
            width={18}
            height={18}
            src="/assets/images/sunshine/ArrowDown21.svg"
            alt="Arrow down"
            className={`transform transition-transform duration-300 ${
              isToggled ? "rotate-180" : ""
            }`}
          />
        </div>
        {isHovered && <div className="hover-line"></div>}
      </TextNew>
      {isToggled && (
        <div className="dropdown-menu absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {dropDownlist.map((service, index) => (
              <div key={index}>
                <div
                  className={`flex justify-between px-4 py-2 font-semibold hover:bg-[#f3f3f3] cursor-pointer w-full transition-colors duration-300 ${
                    submenuIndex === index
                      ? "text-orange-500"
                      : "text-[#232323]"
                  }`}
                  onClick={() => {
                    if (service.link) {
                      window.open(service.link, "_blank");
                    } else {
                      toggleSubmenu(index);
                    }
                  }}
                >
                  {service.name}
                  {service.submenu && service.submenu.length > 0 && (
                    <Image
                      width={18}
                      height={18}
                      src="/assets/images/sunshine/arrowRight1.png"
                      alt="Arrow down"
                      className={`ml-auto transform transition-transform duration-300 h-4 ${
                        submenuIndex === index ? "rotate-90" : "rotate-0"
                      }`}
                    />
                  )}
                </div>

                {submenuIndex === index && service.submenu && (
                  <div className=" bg-gray-100">
                    {service.submenu.map((subItem, subIndex) => (
                      <a
                        key={subIndex}
                        href={subItem.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="block px-4 py-2 pl-10 text-[#232323] font-semibold hover:bg-[#FCE6D8]"
                      >
                        {subItem.name}
                      </a>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const HeaderDropdownCommon = ({
  bgColor,
  services,
  locations,
  onClickContactUs,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);

  const [activeLocation, setActiveLocation] = useState(null);

  const toggleDropdown = (index) => {
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  const onClickFooter = (section) => {
    console.log(`Scrolling to section: ${section}`);
  };

  const ContactUs = useRef(null);
  const onClickContact = (value) => {
    if (value === "ContactUs") {
      ContactUs.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const toggleLocation = (index) => {
    setActiveLocation(activeLocation === index ? null : index);
  };

  return (
    <div className={`py-2 md:px-0 ${bgColor}`}>
      <div className="flex justify-between items-center w-11/12 md:max-w-[1280px] mx-auto">
        <div className="hidden md:block">
          <div className="flex items-center text-white text-2xl font-bold -ml-4">
            <a
              href={`https://sunshine.lissun.app/`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                width={219}
                height={76}
                src={logoUrl}
                alt="Sunshine logo"
                className="ml-8 max-w-full aspect-[2.44] max-md:ml-2.5"
              />
            </a>
          </div>
        </div>

        <div className="md:hidden flex items-center justify-between w-full">
          <div className="flex items-center">
            <a
              href={`https://sunshine.lissun.app/`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                width={120}
                height={45}
                src={logoUrl}
                alt="Sunshine logo"
                className="max-w-full"
              />
            </a>
          </div>
          <button className="outline-none" onClick={() => setIsOpen(!isOpen)}>
            <svg
              className="w-6 h-6 text-white"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
        </div>

        <div
          className={`fixed top-0 right-0 h-screen bg-white shadow-lg transition-transform duration-300 ease-in-out z-[1000] ${
            isOpen ? "translate-x-0" : "translate-x-full"
          } w-8/12`}
        >
          <button
            className="absolute top-4 right-4"
            onClick={() => setIsOpen(false)}
          >
            <svg
              className="w-6 h-6 text-gray-500"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
          <ul className="mt-16">
            <li>
              <a
                href={`https://sunshine.lissun.app/`}
                className="block text-sm sm:text-lg px-4 py-4 text-gray-500  hover:text-black font-semibold"
                target="_blank"
                rel="noopener noreferrer"
              >
                Home
              </a>
            </li>
            <li>
              <button
                className="block w-full text-sm sm:text-lg px-4 py-2 text-black font-semibold flex justify-between items-center"
                onClick={() => toggleDropdown(1)}
              >
                Services
                {activeDropdown === 1 ? (
                  <Image
                    width={32}
                    height={32}
                    src="/assets/images/sunshine/arrowRight1.png"
                    alt="Up Arrow"
                    className="h-8 w-8 -rotate-90"
                  />
                ) : (
                  <Image
                    width={32}
                    height={32}
                    src="/assets/images/sunshine/arrowRight1.png"
                    alt="Down Arrow"
                    className="h-8 w-8 rotate-90"
                  />
                )}
              </button>
              {activeDropdown === 1 && (
                <ul className="bg-gray-100">
                  {services.map((service, index) => (
                    <li key={index}>
                      <a
                        href={service.link}
                        className="block text-sm sm:text-lg px-4 py-2 text-gray-500  hover:text-black font-semibold"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {service.name}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </li>

            <li>
              <button
                className="block w-full text-sm sm:text-lg px-4 py-2 text-black font-semibold flex justify-between items-center"
                onClick={() => toggleDropdown(2)}
              >
                Locations
                {activeDropdown === 2 ? (
                  <Image
                    width={32}
                    height={32}
                    src="/assets/images/sunshine/arrowRight1.png"
                    alt="Up Arrow"
                    className="h-8 w-8 -rotate-90"
                  />
                ) : (
                  <Image
                    width={32}
                    height={32}
                    src="/assets/images/sunshine/arrowRight1.png"
                    alt="Down Arrow"
                    className="h-8 w-8 rotate-90"
                  />
                )}
              </button>
              {activeDropdown === 2 && (
               <ul className="bg-gray-100 flex flex-col lg:flex-row">
               {locations.map((location, index) => (
                 <li key={index} className="relative">
                   <button
                     className={`block text-sm sm:text-lg px-4 py-2 font-semibold w-full flex justify-between items-center transition-colors duration-300 pr-6 ${
                       activeLocation === index ? "text-orange-500" : "text-gray-500 hover:text-black"
                     }`}
                     onClick={() => toggleLocation(index)}
                   >
                     {location.name}
                     {activeLocation === index ? (
                       <Image
                         width={16}
                         height={16}
                         src="/assets/images/sunshine/arrowRight1.png"
                         alt="Up Arrow"
                         className="h-6 w-6 -rotate-90"
                       />
                     ) : (
                       <Image
                         width={16}
                         height={16}
                         src="/assets/images/sunshine/arrowRight1.png"
                         alt="Down Arrow"
                         className="h-6 w-6 rotate-90"
                       />
                     )}
                   </button>
                   {activeLocation === index && (
                     <ul className="bg-gray-200 pl-4 flex flex-col items-start lg:flex-row lg:space-x-4">
                       {location.submenu.map((subOption, subIndex) => (
                         <li key={subIndex}>
                           <a
                             href={subOption.link}
                             className="block text-sm sm:text-lg px-4 py-2 text-gray-500 hover:text-black font-semibold"
                             target="_blank"
                             rel="noopener noreferrer"
                           >
                             {subOption.name}
                           </a>
                         </li>
                       ))}
                     </ul>
                   )}
                 </li>
               ))}
             </ul>
             
              )}
            </li>

            <li>
              <a
                href="#contact-us"
                className="block text-sm sm:text-lg px-4 py-4 text-gray-500  hover:text-black font-semibold"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => onClickContactUs("contactUsSunshineHeader")}
              >
                Contact Us
              </a>
            </li>
          </ul>
        </div>

        <div className="hidden md:block">
          <nav className="space-x-2 lg:space-x-8 flex items-center">
            <TextNew
              variant="body"
              fontSize="text-xs md:text-base"
              fontWeight="!font-medium"
              fontFamily="font-Nunito"
              className="hover-underline"
            >
              <a
                href={`https://sunshine.lissun.app/`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-white no-underline"
              >
                Home
              </a>
            </TextNew>
            <DropDown dropDownname={"Services"} dropDownlist={services} />
            <DropDown dropDownname={"Locations"} dropDownlist={locations} />

            <TextNew
              variant="body"
              fontSize="text-xs md:text-base"
              fontWeight="!font-medium"
              fontFamily="font-Nunito"
              className="hover-underline cursor-pointer"
            >
              <a
                className="text-white no-underline whitespace-nowrap"
                // onClick={() => onClickFooter("contactUsSection")}
                onClick={() => onClickContactUs("contactUsSunshineHeader")}
              >
                Contact Us
              </a>
            </TextNew>
          </nav>
        </div>
      </div >
    </div>
  );
};

export default HeaderDropdownCommon;
