import Text from "@atoms/Text";
import TextNew from "@atoms/TextNew";
import Image from "next/image";
import React from "react";

const UniqueSunshine = ({
    title,
    imageUrl1,
    sentence1,
    sentence2,
    sentence3,
    index,
    mainImage,
}) => {
    return (
        <>
            <div
                className={`${index == 1 ? "flex-row md:flex md:flex-row-reverse" : "flex-row md:flex"
                    } justify-center items-center gap-8 pb-10 md:pb-12`}
            >
                <div className="lg:hidden block flex justify-center">
                    <Image src={mainImage} width={220} height={165} />
                </div>
                <div className="hidden lg:block">
                    <Image src={mainImage} width={542} height={397} />
                </div>
                <div className="items-stretch flex flex-col mt-4 md:mb-16 px-4 md:px-0">
                    <div className="text-zinc-800 text-base md:text-2xl font-bold leading-8 mt-1.5 mb-4 md:mb-6">
                        {title}
                    </div>
                    <div className="items-stretch flex flex-col gap-2 lg:gap-4">
                        <div className="flex items-center gap-2.5 flex-1 max-w-[440px]">
                            <div className="aspect-square object-contain object-center w-[30px] overflow-hidden shrink-0 max-w-full hidden lg:block ">
                                <Image
                                    width={30}
                                    height={30}
                                    loading="lazy"
                                    src={imageUrl1}

                                />
                            </div>

                            <div className="aspect-square object-contain object-center w-[15px] -mt-1 overflow-hidden shrink-0 max-w-full lg:hidden block">
                                <Image
                                    width={12}
                                    height={12}
                                    loading="lazy"
                                    src={imageUrl1}
                                />
                            </div>

                            {/* <Text className="text-zinc-800 text-xs md:text-base leading-4  md:leading-6">
                                {sentence1}
                            </Text> */}
                            <TextNew variant="caption" className="text-[#232323] h-fit leading-4 md:leading-6  font-normal mt-1">
                            {sentence1}
                            </TextNew>
                        </div>
                        <div className="flex items-center gap-2.5 flex-1 max-w-[440px] ">
                            <div className="aspect-square object-contain object-center w-[30px] overflow-hidden shrink-0 max-w-full hidden lg:block ">
                                <Image
                                    width={30}
                                    height={30}
                                    loading="lazy"
                                    src={imageUrl1}

                                />
                            </div>

                            <div className="aspect-square object-contain object-center w-[15px] -mt-1 overflow-hidden shrink-0 max-w-full lg:hidden block">
                                <Image
                                    width={12}
                                    height={12}
                                    loading="lazy"
                                    src={imageUrl1}
                                />
                            </div>


                            {/* <Text className="text-zinc-800 text-xs leading-5  md:text-base   md:leading-6">
                                {sentence2}
                            </Text> */}
                            <TextNew variant="caption" className="text-[#232323] h-fit leading-4 md:leading-6  font-normal mt-1">
                            {sentence2}
                            </TextNew>
                        </div>
                        <div className="flex items-center gap-2.5 flex-1 max-w-[440px] ">
                            <div className="aspect-square object-contain object-center w-[30px] overflow-hidden shrink-0 max-w-full hidden lg:block ">
                                <Image
                                    width={30}
                                    height={30}
                                    loading="lazy"
                                    src={imageUrl1}

                                />
                            </div>

                            <div className="aspect-square object-contain object-center w-[15px] -mt-1 overflow-hidden shrink-0 max-w-full lg:hidden block">
                                <Image
                                    width={12}
                                    height={12}
                                    loading="lazy"
                                    src={imageUrl1}
                                />
                            </div>

                            {/* <Text className="text-zinc-800 text-xs md:text-base leading-4  md:leading-6">
                                {sentence3}
                            </Text> */}
                            <TextNew variant="caption" className="text-[#232323] h-fit leading-4 md:leading-6  font-normal mt-1">
                                {sentence3}
                            </TextNew>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UniqueSunshine;
