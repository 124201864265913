import Container from "@atoms/Container";
import HeadingNew from "@atoms/HeadingNew";
import TextNew from "@atoms/TextNew";
import Image from "next/image";
import * as React from "react";
import BookingFormSunshine from "./BookingFormSunshine";
import Carousel from "@molecules/Carousel";
const HeroBanner = ({ consultationData, onClose }) => {
  return (
    <section className="relative bg-top md:bg-center bg-cover  bg-hero-banner-mob bg-no-repeat md:bg-hero-banner-sunshine z-20 md:pb-0 pb-[10px] overflow-hidden">
      <div className="absolute md:hidden block -bottom-[22px] z-10">
        <Image
          src="/assets/images/sunshine/HeroBottom.svg"
          width={400}
          height={35}
        />
      </div>
      <Container type="type0">
        <div className="flex-none md:flex justify-between h-full pt-4 md:pt-11">
          <div className="max-w-[600px] z-30 py-5 md:p-0 md:ml-0 lg:mt-10 md:text-left">
            <HeadingNew
              type="h1"
              fontSize="text-xl lg:text-[40px]"
              fontWeight="font-medium "
              className=" leading-normal text-left mb-4 text-white  "
            >
              Unlock your child’s potential and help them thrive!
            </HeadingNew>

            <TextNew
              type="caption"
              fontSize="text-xs lg:text-base"
              className="text-left mb-2 font-normal leading-5 text-white"
            >
              We at Sunshine by LISSUN have started a chain of clinics to
              provide world class child development services. We specialise in
              neurodevelopmental and behavioral disorders especially Autism
              Spectrum, ADHD & Learning Disorders.
            </TextNew>
          </div>
          <div className="relative pt-11 md:pt-11 text-center">
            <div className="text-center absolute -mt-[45px] -left-[100px] w-[120%] md:w-[150%] hidden md:block">
              <Image
                src="/assets/images/sunshine/cloudHero.png"
                width={630}
                height={480}
                className=""
              />
            </div>

            <div className={"relative"}>
              <BookingFormSunshine
                className="relative z-50"
                onClose={onClose}
              />
              <div className="absolute md:hidden block -right-4 -bottom-8 z-10">
                <Image
                  src="/assets/images/sunshine/CloudFormRight.png"
                  width={75}
                  height={45}
                />
              </div>
              <div className="absolute md:hidden block -left-4 -bottom-8 z-10">
                <Image
                  src="/assets/images/sunshine/CloudFormLeft.png"
                  width={75}
                  height={45}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
      <div className="centers mt-16 mb-5 relative">
        <div>
          <div className="absolute hidden md:block -top-12 lg:-top-16 xl:-top-20 lg:left-10 ">
            <Image
              src="/assets/images/sunshine/Grouphero.svg"
              width={1400}
              height={118}
            />
          </div>
          <div className="absolute md:hidden block -top-9">
            <Image
              src="/assets/images/sunshine/HeroAnimalillustration.png"
              width={400}
              height={45}
            />
          </div>
        </div>

        <div className="w-full relative z-10 bg-[#FEFBEF] pt-6 shadow-sm">
          <HeadingNew
            type="h1"
            fontSize="text-base lg:text-2xl"
            fontWeight="font-medium"
            className=" leading-normal text-center m-auto"
          >
            Contact our Centers:
          </HeadingNew>

          <div className="reviews-slider w-10/12 lg::max-w-[1100px] mx-auto hidden md:block">
            <Carousel
              slidesToShow={5}
              arrows={true}
              dots={false}
              autoPlay={false}
              speed={5000}
              arrowPosition={"topCenter"}
              iconBg=""
              iconSize="w-9 md:w-16 h-9 md:h-16"
              leftArrowIcon={
                <Image
                  src="/assets/images/sunshine/CarouselArrowLeft.png"
                  width={64}
                  height={64}
                  alt=""
                />
              }
              rightArrowIcon={
                <Image
                  src="/assets/images/sunshine/CarouselArrowRight.png"
                  width={64}
                  height={64}
                  alt=""
                />
              }
              prevIconClass="relative -left-4 lg:-left-5 xl:-left-10"
              nextIconClass="relative -right-8 lg:-right-5 xl:-right-10"
              responsive={[
               
                {
                  breakpoint: 1023,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    speed: 1500,
                  },
                },
                {
                  breakpoint: 1279,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    speed: 1500,
                  },
                },
                {
                  breakpoint: 2920,
                  settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    speed: 1500,
                  },
                },
              ]}
            >
              <div className="lg:pl-10 py-4 md:py-6 ">
                <a
                  href="https://www.lissun.app/sunshine/child-counseling-mental-health-service-in-delhi"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <HeadingNew
                    type="h4"
                    fontWeight="font-medium"
                    fontColor="text-[#EF833A]"
                    className="text-2xl text-center"
                  >
                    Delhi
                  </HeadingNew>
                </a>
                <TextNew
                  variant="body"
                  className="mt-4 text-neutral-800 text-center"
                >
                  +91 9821688144
                </TextNew>
              </div>

              <div className="lg:pl-10 py-4 md:py-6 ">
                <a
                  href="https://www.lissun.app/sunshine/child-counseling-mental-health-service-in-gurgaon"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <HeadingNew
                    type="h4"
                    fontWeight="font-medium"
                    fontColor="text-[#F47A82]"
                    className="text-2xl text-center"
                  >
                    Gurgaon
                  </HeadingNew>
                </a>
                <TextNew
                  variant="body"
                  className="mt-4 text-neutral-800 text-center"
                >
                  +91 9717857568
                </TextNew>
              </div>

              <div className="lg:pl-10 py-4 md:py-6">
                <a
                  href="https://www.lissun.app/sunshine/child-counseling-mental-health-service-in-pune"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <HeadingNew
                    type="h4"
                    fontWeight="font-medium"
                    fontColor="text-[#88C399]"
                    className="text-2xl text-center"
                  >
                    Pune
                  </HeadingNew>
                </a>
                <TextNew
                  variant="body"
                  className="mt-4 text-neutral-800 text-center"
                >
                  +91 9717858269
                </TextNew>
              </div>

              <div className="lg:pl-10 py-4 md:py-6">
                <a
                  href="https://www.lissun.app/sunshine/child-counseling-mental-health-service-in-lucknow"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <HeadingNew
                    type="h4"
                    fontWeight="font-medium"
                    fontColor="text-[#5C7AAF]"
                    className="text-2xl text-center"
                  >
                    Lucknow
                  </HeadingNew>
                </a>
                <TextNew
                  variant="body"
                  className="mt-4 text-neutral-800 text-center"
                >
                  +91 9717857643
                </TextNew>
              </div>

              <div className="lg:pl-10 py-4 md:py-6 ">
                <a
                  href="https://www.lissun.app/sunshine/child-counseling-mental-health-service-in-Indirapuram"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <HeadingNew
                    type="h4"
                    fontWeight="font-medium"
                    fontColor="text-[#64C4D3]"
                    className="text-2xl text-center"
                  >
                    Indirapuram
                  </HeadingNew>
                </a>
                <TextNew
                  variant="body"
                  className="mt-4 text-neutral-800 text-center"
                >
                  +91 9821688144
                </TextNew>
              </div>

              <div className="lg:pl-10 py-4 md:py-6 ">
                <a
                  href="https://www.lissun.app/sunshine/child-counseling-mental-health-service-in-delhi"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <HeadingNew
                    type="h4"
                    fontWeight="font-medium"
                    fontColor="text-[#EF833A]"
                    className="text-2xl text-center"
                  >
                    Kolkata
                  </HeadingNew>
                </a>
                <TextNew
                  variant="body"
                  className="mt-4 text-neutral-800 text-center"
                >
                  +91 9821688144
                </TextNew>
              </div>

              <div className="lg:pl-10 py-4 md:py-6 ">
                <a
                  href="https://www.lissun.app/sunshine/child-counseling-mental-health-service-in-gurgaon"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <HeadingNew
                    type="h4"
                    fontWeight="font-medium"
                    fontColor="text-[#F47A82]"
                    className="text-2xl text-center"
                  >
                    Ahmedabad
                  </HeadingNew>
                </a>
                <TextNew
                  variant="body"
                  className="mt-4 text-neutral-800 text-center"
                >
                  +91 9717857568
                </TextNew>
              </div>

              <div className="lg:pl-10 py-4 md:py-6">
                <a
                  href="https://www.lissun.app/sunshine/child-counseling-mental-health-service-in-pune"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <HeadingNew
                    type="h4"
                    fontWeight="font-medium"
                    fontColor="text-[#88C399]"
                    className="text-2xl text-center"
                  >
                    Jaipur
                  </HeadingNew>
                </a>
                <TextNew
                  variant="body"
                  className="mt-4 text-neutral-800 text-center"
                >
                  +91 9717858269
                </TextNew>
              </div>

              <div className="lg:pl-10 py-4 md:py-6">
                <a
                  href="https://www.lissun.app/sunshine/child-counseling-mental-health-service-in-lucknow"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <HeadingNew
                    type="h4"
                    fontWeight="font-medium"
                    fontColor="text-[#5C7AAF]"
                    className="text-2xl text-center"
                  >
                    Chandigarh
                  </HeadingNew>
                </a>
                <TextNew
                  variant="body"
                  className="mt-4 text-neutral-800 text-center"
                >
                  +91 9717857643
                </TextNew>
              </div>

              <div className="lg:pl-10 py-4 md:py-6 ">
                <a
                  href="https://www.lissun.app/sunshine/child-counseling-mental-health-service-in-Indirapuram"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <HeadingNew
                    type="h4"
                    fontWeight="font-medium"
                    fontColor="text-[#64C4D3]"
                    className="text-2xl text-center"
                  >
                    Bangalore
                  </HeadingNew>
                </a>
                <TextNew
                  variant="body"
                  className="mt-4 text-neutral-800 text-center"
                >
                  +91 9821688144
                </TextNew>
              </div>
            </Carousel>
          </div>

          <div className="reviews-slider w-10/12 lg::max-w-[1100px] mx-auto md:hidden block">
            <Carousel
              slidesToShow={5}
              arrows={true}
              dots={false}
              autoPlay={false}
              speed={5000}
              arrowPosition={"topCenter"}
              iconBg=""
              iconSize="w-9 md:w-16 h-9 md:h-16"
              leftArrowIcon={
                <Image
                  src="/assets/images/sunshine/leftArrow.svg"
                  width={64}
                  height={64}
                  alt=""
                />
              }
              rightArrowIcon={
                <Image
                  src="/assets/images/sunshine/rightArrow.svg"
                  width={64}
                  height={64}
                  alt=""
                />
              }
              prevIconClass="relative -left-0 lg:-left-5 xl:-left-10"
              nextIconClass="relative -left-0 lg:-right-5 xl:-right-10"
              responsive={[
                {
                  breakpoint: 767,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    speed: 1500,
                  },
                },
                {
                  breakpoint: 1023,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    speed: 1500,
                  },
                },
                {
                  breakpoint: 1279,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    speed: 1500,
                  },
                },
                {
                  breakpoint: 2920,
                  settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    speed: 1500,
                  },
                },
              ]}
            >
              <div>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-7">
                  <div className="lg:pl-10 py-4 md:py-6 ">
                    <a
                      href="https://www.lissun.app/sunshine/child-counseling-mental-health-service-in-delhi"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <HeadingNew
                        type="h4"
                        fontWeight="font-medium"
                        fontColor="text-[#EF833A]"
                        className="text-2xl text-center"
                      >
                        Delhi
                      </HeadingNew>
                    </a>
                    <TextNew
                      variant="body"
                      className="mt-4 text-neutral-800 text-center"
                    >
                      +91 9821688144
                    </TextNew>
                  </div>

                  <div className="lg:pl-10 py-4 md:py-6 ">
                    <a
                      href="https://www.lissun.app/sunshine/child-counseling-mental-health-service-in-gurgaon"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <HeadingNew
                        type="h4"
                        fontWeight="font-medium"
                        fontColor="text-[#F47A82]"
                        className="text-2xl text-center"
                      >
                        Gurgaon
                      </HeadingNew>
                    </a>
                    <TextNew
                      variant="body"
                      className="mt-4 text-neutral-800 text-center"
                    >
                      +91 9717857568
                    </TextNew>
                  </div>

                  <div className="lg:pl-10 py-4 md:py-6">
                    <a
                      href="https://www.lissun.app/sunshine/child-counseling-mental-health-service-in-pune"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <HeadingNew
                        type="h4"
                        fontWeight="font-medium"
                        fontColor="text-[#88C399]"
                        className="text-2xl text-center"
                      >
                        Pune
                      </HeadingNew>
                    </a>
                    <TextNew
                      variant="body"
                      className="mt-4 text-neutral-800 text-center"
                    >
                      +91 9717858269
                    </TextNew>
                  </div>

                  <div className="lg:pl-10 py-4 md:py-6">
                    <a
                      href="https://www.lissun.app/sunshine/child-counseling-mental-health-service-in-lucknow"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <HeadingNew
                        type="h4"
                        fontWeight="font-medium"
                        fontColor="text-[#5C7AAF]"
                        className="text-2xl text-center"
                      >
                        Lucknow
                      </HeadingNew>
                    </a>
                    <TextNew
                      variant="body"
                      className="mt-4 text-neutral-800 text-center"
                    >
                      +91 9717857643
                    </TextNew>
                  </div>
                </div>
              </div>
              <div>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-7">
                <div className="lg:pl-10 py-4 md:py-6 ">
                    <a
                      href="https://www.lissun.app/sunshine/child-counseling-mental-health-service-in-Indirapuram"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <HeadingNew
                        type="h4"
                        fontWeight="font-medium"
                        fontColor="text-[#64C4D3]"
                        className="text-2xl text-center"
                      >
                        Indirapuram
                      </HeadingNew>
                    </a>
                    <TextNew
                      variant="body"
                      className="mt-4 text-neutral-800 text-center"
                    >
                      +91 9821688144
                    </TextNew>
                  </div>
                <div className="lg:pl-10 py-4 md:py-6 ">
                <a
                  href="https://www.lissun.app/sunshine/child-counseling-mental-health-service-in-gurgaon"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <HeadingNew
                    type="h4"
                    fontWeight="font-medium"
                    fontColor="text-[#F47A82]"
                    className="text-2xl text-center"
                  >
                    Ahmedabad
                  </HeadingNew>
                </a>
                <TextNew
                  variant="body"
                  className="mt-4 text-neutral-800 text-center"
                >
                  +91 9717857568
                </TextNew>
              </div>

              <div className="lg:pl-10 py-4 md:py-6">
                <a
                  href="https://www.lissun.app/sunshine/child-counseling-mental-health-service-in-pune"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <HeadingNew
                    type="h4"
                    fontWeight="font-medium"
                    fontColor="text-[#88C399]"
                    className="text-2xl text-center"
                  >
                    Jaipur
                  </HeadingNew>
                </a>
                <TextNew
                  variant="body"
                  className="mt-4 text-neutral-800 text-center"
                >
                  +91 9717858269
                </TextNew>
              </div>

              <div className="lg:pl-10 py-4 md:py-6 ">
                <a
                  href="https://www.lissun.app/sunshine/child-counseling-mental-health-service-in-Indirapuram"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <HeadingNew
                    type="h4"
                    fontWeight="font-medium"
                    fontColor="text-[#64C4D3]"
                    className="text-2xl text-center"
                  >
                    Bangalore
                  </HeadingNew>
                </a>
                <TextNew
                  variant="body"
                  className="mt-4 text-neutral-800 text-center"
                >
                  +91 9821688144
                </TextNew>
              </div>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
};
export default HeroBanner;
