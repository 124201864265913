import Carousel from "@molecules/Carousel";
import LocationCard from "@molecules/LocationCard";
import Image from "next/image";
import * as React from "react";

const LocationSection = ({ locationList }) => {
  const groupedLocations = (locationList || []).reduce((acc, curr, index) => {
    const groupIndex = Math.floor(index / 2);
    if (!acc[groupIndex]) {
      acc[groupIndex] = [];
    }
    acc[groupIndex].push(curr);
    return acc;
  }, []);

  return (
    <>
    <div className="md:hidden block">
    <Carousel
      responsive={[
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: true,
          },
        },
       
      ]}
      slidesToShow={2}
      dots={true}
      arrows={false}
      autoPlay={true}
      speed={1800}
      iconBg=""
      iconSize="w-9 md:w-16 h-9 md:h-16"
      leftArrowIcon={
        <Image
          src="/assets/images/sunshine/leftArrow.svg"
          width={64}
          height={64}
          alt=""
        />
      }
      rightArrowIcon={
        <Image
          src="/assets/images/sunshine/rightArrow.svg"
          width={64}
          height={64}
          alt=""
        />
      }
    >
      {groupedLocations.map((group, groupIndex) => (
        <div key={groupIndex} className="location-card-group mb-4 md:mb-0">
          <div className="flex flex-col lg:flex-row items-center justify-between pr-6 gap-4 xl:gap-6">
            {group.map(
              ({
                location,
                textColor,
                address,
                phone,
                email,
                viewMorelink,
              }) => (
                <LocationCard
                  key={location}
                  textColor={textColor}
                  location={location}
                  address={address}
                  phone={phone}
                  email={email}
                  viewMorelink={viewMorelink}
                />
              )
            )}
          </div>
        </div>
      ))}
    </Carousel>
    </div>
    <div className="hidden md:block">
        <Carousel
        responsive={[
          {
            breakpoint: 2500,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              arrows: true,
              dots: false,
            },
          },
        ]}
        slidesToShow={2}
        dots={false}
        arrows={true}
        autoPlay={false}
        speed={1500}
        iconBg=""
        iconSize="w-9 md:w-16 h-9 md:h-16"
        leftArrowIcon={
          <Image
            src="/assets/images/sunshine/leftArrow.svg"
            width={64}
            height={64}
            alt="Left Arrow"
          />
        }
        rightArrowIcon={
          <Image
            src="/assets/images/sunshine/rightArrow.svg"
            width={64}
            height={64}
            alt="Right Arrow"
          />
        }
      >
        {groupedLocations.map((group, groupIndex) => (
          <div key={groupIndex} className="location-card-group mb-4 md:mb-0">
            <div className="flex flex-col lg:flex-row items-center justify-between pr-6 gap-4 xl:gap-6">
              {group.map(
                ({
                  location,
                  textColor,
                  address,
                  phone,
                  email,
                  viewMorelink,
                }) => (
                  <LocationCard
                    key={location}
                    textColor={textColor}
                    location={location}
                    address={address}
                    phone={phone}
                    email={email}
                    viewMorelink={viewMorelink}
                  />
                )
              )}
            </div>
          </div>
        ))}
      </Carousel>
    </div>
    </>
  );
};

export default LocationSection;
