import Heading from "@atoms/Heading";
import React from "react";
import Text from "@atoms/Text";
import Button from "@atoms/Button";
import HeadingNew from "@atoms/HeadingNew";
import TextNew from "@atoms/TextNew";
import Image from "next/image";
function ResponseSubmit({ close }) {
  return (
    <div className="dialog fixed flex items-center justify-center w-full h-full top-0 left-0 bg-black bg-opacity-40 z-[100]">
      <div className=" relative w-11/12 md:max-w-[560px] rounded-[20px] p-6 bg-white border-gray-400 border ">
        <div className="flex items-center mb-4 gap-2">
          <Image
          width={44}
          height={44}
            src="/assets/images/sunshine/Tick.svg"
            className="cursor-pointer mr-3"
          />
          <HeadingNew
            type="h4"
            fontWeight="font-semibold"
            className="w-full font-Nunito text-base  font-bold"
          >
            Response Submitted Successfully!
          </HeadingNew>
        </div>
        <div className={``}>
          <TextNew
            variant="caption"
            className="font-normal ml-8 mb-6"
          >
            Thanks for reaching out to us, our team will get back to you soon
          </TextNew>
        </div>
        <div className="flex items-center justify-center">
          <Button
            type="sunshineSecondary"
            padding="px-8"
            className=" py-2 mb-4 w-40 bg-secondary-700 border-none"
            onClick={close}
          >
            Okay
          </Button>
        </div>
      </div>
    </div>

  );
}

export default ResponseSubmit;


