import * as React from "react";
import Image from "next/image";
import Carousel from "@molecules/Carousel";
const SunshineStorySlider = () => {
    const settings = {
        arrows: false,
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 6,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 6,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <Carousel
            {...settings}
            iconBg="bg-white border border-black rounded-full text-black"
        >
            <div className="text-center">
                <div className="hidden md:block">
                    <Image
                        src={"/assets/images/sunshine/story-1.svg"}
                        width={210}
                        height={754}
                        objectFit="cover"
                    />
                </div>
                {/* <div className="block md:hidden">
                    <Image
                        src={"/assets/images/sunshine/story-1.svg"}
                        width={252}
                        height={564}
                    />
                </div> */}
            </div>
            <div className="text-center">
                <div className="hidden md:block">
                    <Image
                        src={"/assets/images/sunshine/story-2.svg"}
                        width={210}
                        height={754}
                        objectFit="cover"
                    />
                </div>
                {/* <div className="block md:hidden">
                    <Image
                        src={"/assets/images/sunshine/story-2.svg"}
                        width={292}
                        height={564}
                    />
                </div> */}
            </div>
            <div className="text-center">
                <div className="hidden md:block">
                    <Image
                        src={"/assets/images/sunshine/story-3.svg"}
                        width={210}
                        height={754}
                        objectFit="cover"
                    />
                </div>
                {/* <div className="block md:hidden">
                    <Image
                        src={"/assets/images/sunshine/story-3.svg"}
                        width={252}
                        height={564}
                    />
                </div> */}
            </div>
            <div className="text-center">
                <div className="hidden md:block">
                    <Image
                        src={"/assets/images/sunshine/story-4.svg"}
                        width={210}
                        height={754}
                        objectFit="cover"
                    />
                </div>
                {/* <div className="block md:hidden">
                    <Image
                        src={"/assets/images/sunshine/story-4.svg"}
                        width={252}
                        height={564}
                    />
                </div> */}
            </div>
            <div className="text-center">
                <div className="hidden md:block">
                    <Image
                        src={"/assets/images/sunshine/story-5.svg"}
                        width={210}
                        height={754}
                        objectFit="cover"
                    />
                </div>
                {/* <div className="block md:hidden">
                    <Image
                        src={"/assets/images/sunshine/story-5.svg"}
                        width={252}
                        height={564}
                    />
                </div> */}
            </div>
            <div className="text-center">
                <div className="hidden md:block">
                    <Image
                        src={"/assets/images/sunshine/story-6.svg"}
                        width={210}
                        height={754}
                        objectFit="cover"
                    />
                </div>
                {/* <div className="block md:hidden">
                    <Image
                        src={"/assets/images/sunshine/story-6.svg"}
                        width={252}
                        height={514}
                    />
                </div> */}
            </div>
        </Carousel>
    );
};

export default SunshineStorySlider;
