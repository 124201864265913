import * as React from "react";
import Button from "@atoms/Button";
import Heading from "@atoms/Heading";
import Image from "next/image";
import HeadingNew from "@atoms/HeadingNew";

const MediaPresenceSection = ({ handleMoreArticle }) => {
    return (
        <section className="relative pt-12 pb-12 md:pt-[100px] md:pb-[140px]">
            <div className="w-11/12 md:max-w-[1193px] mx-auto ">
                <div className="">
                    <HeadingNew
                        type={"h2"}
                        fontWeight="font-semibold"
                        className="text-center  "
                        fontSize="text-xl lg:text-[40px]"
                    >
                        Sunshine’s Media Presence
                    </HeadingNew>
                </div>

                <div className="grid grid-cols-2 gap-6 md:grid-cols-3 px-8 lg:px-4 mt-8 md:mt-14 flex-wrap">
                    <div className="p-2 md:p-4 text-center">
                    <Image
                        src={"/assets/images/media1.png"}
                        width={200}
                        height={114}
                        objectFit="contain"
                    />
                    </div>
                    <div className="p-2 md:p-4 text-center">
                    <Image
                        src={"/assets/images/media2.png"}
                        width={200}
                        height={114}
                        objectFit="contain"
                    />
                    </div>
                    <div className="p-2 md:p-4 text-center">
                    <Image
                        src={"/assets/images/media3.png"}
                        width={200}
                        height={114}
                        objectFit="contain"
                    />
                    </div>
                    <div className="p-2 md:p-4 text-center">
                    <Image
                        src={"/assets/images/media4.png"}
                        width={200}
                        height={114}
                        objectFit="contain"
                    />
                    </div>
                    <div className="p-2 md:p-4 text-center">
                    <Image
                        src={"/assets/images/media5.png"}
                        width={200}
                        height={114}
                        objectFit="contain"
                    />
                    </div>
                    <div className="p-2 md:p-4 text-center">
                    <Image
                        src={"/assets/images/media6.png"}
                        width={200}
                        height={114}
                        objectFit="contain"
                    />
                    </div>
                </div>
                <div className="w-full flex justify-center rounded-lg lg:rounded-xl mt-5  md:mt-16 mb-10 md:mb-0 cursor-pointer z-50 relative ">
                    <Button className="cursor-pointer w-[200px] lg:w-[300px] " variant="sunshinePrimaryGreen"   onClick={handleMoreArticle}>
                        View more articles 
                    </Button>
                </div>
                
            </div>
            <div className="flex justify-between items-end max-w-[1193px] w-full mx-auto absolute left-1/2 -bottom-1.5 -translate-x-1/2">
                <div className="max-w-[100px] md:max-w-max">
                    <Image
                        src="/assets/images/sunshine/media-icon-1.png"
                        width={209}
                        height={105}
                    />
                </div>
                <div className="max-w-[100px] md:max-w-max">
                    <Image
                        src="/assets/images/sunshine/media-icon-2.png"
                        width={190}
                        height={190}
                    />
                </div>
            </div>
        </section>
    );
};

export default MediaPresenceSection;
