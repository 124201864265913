import Button from "@atoms/Button";
import Heading from "@atoms/Heading";
import Text from "@atoms/Text";
import Image from "next/image";
import Link from "next/link";
import React from "react";

function BookASession({ handleBookAppointment }) {
    return (
        <div className=" relative z-50 flex flex-col md:flex-row items-center gap-x-12 bg-[#88C399] mx-auto rounded-[20px] md:rounded-2xl md:py-8 lg:py-12  p-5 md:p-8 w-11/12 md:w-10/12  xl:max-w-[1163px]  md:text-left text-center">
            <div className="w-full md:w-4/6 mb-8 md:mb-0 relative z-50">
                <Heading
                    type="h2"
                    className="text-base md:text-lg lg:text-[32px] xl:text-[40px]  leading-10 lg:leading-[48px] text-white md:mb-4 mb-1.5"
                >
                    Book a session for yourself here
                </Heading>

                <Text className="text-xs  lg:text-2xl leading-4 lg:leading-8 !text-white">
                    In the process of taking care of your child, don’t forget to
                    take care of yourself too.
                </Text>
            </div>
            <div className="w-full md:w-2/6">
                <Link href="https://www.lissun.app/connect-with-the-best-mental-health-counsellor-&-therapist-in-india">
                    <a target="_blank">
                        <Button
                            variant="sunshinePrimaryGreenText"
                            onClick={handleBookAppointment}
                            width="w-full"
                        >
                            Book an Online Appointment
                        </Button>
                    </a>
                </Link>
            </div>
        </div>
    );
}

export default BookASession;
