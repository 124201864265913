import HeadingNew from "@atoms/HeadingNew";
import Text from "@atoms/Text";
import TextNew from "@atoms/TextNew";
import React from "react";

const ChildAssessmentServices = ({
  imageSrcSet,
  heading,
  description,
}) => {
  return (
    <div className=" shadow-lg bg-white max-w-[540px] px-6 md:px-12 md:py-8 py-6 rounded-3xl z-10 relative">
      <div className="gap-5 flex  justify-between items-center">
        <div className="w-full md:w-4/5">
          <HeadingNew
            type="h4"
            fontSize="text-sm md:text-base lg:text-2xl"
            fontWeight="font-semibold"
            className="overflow-hidden text-left   "
          >
            {heading}
          </HeadingNew>
          <TextNew variant="caption" className="text-textGrey h-fit text-left  leading-4 md:leading-6  font-normal mt-1">
            {description}
          </TextNew>

        </div>
        <div className="">
          <img
            loading="lazy"
            srcSet={imageSrcSet}
            className="aspect-square object-contain object-center w-16 h-16 md:w-full md:mt-0"
          />
        </div>
      </div>
    </div>
  );
};

export default ChildAssessmentServices;
