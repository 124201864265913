import SunShineLanding from '@components/Sunshine/SunShineLanding'
import { getNewsOrderings } from '@services/content.service'
import { getTherapistByFilter } from '@services/therapist.service'
import { useRouter } from 'next/router'
import React from 'react'

function Index({therapists,mediaArticleList}) {
  const router = useRouter()
  return (
    <SunShineLanding therapists={therapists} mediaArticleList={mediaArticleList} handleMoreArticle={()=> router.push('/media')} />
  )
}

export default Index

export async function getStaticProps() {
  const response = await getTherapistByFilter({ from: "sunshine" })
  const newsResponse=await getNewsOrderings('SUNSHINE_NEWS')
  return {
    props: {
      therapists: response.status ? response.entity : [],
      mediaArticleList: newsResponse.status ? newsResponse.entity.rows : []
    },
    revalidate: 3600
  }
}