import Image from "next/image";
import React from "react";

const ComingSoonBanner = ({
  message,
  linkText,
  linkUrl,
  bgColor,
  textColor,
  linkColor,
  imgSrc,
  imgSrc1,
  imgAlt,
  imgWidth = 48,
  imgHeight = 48,
}) => {
  return (
    <div className={`py-3 px-4 flex items-center justify-center w-full font-semibold ${bgColor}`}>
      <div className="infinity-move rounded-full overflow-hidden flex-0 mr-4">
        <Image width={imgWidth} height={imgHeight} src={imgSrc} alt={imgAlt} />
      </div>
      <span className={`infinity-move font-Nunito font-bold md:font-poppins md:font-semibold text-sm leading-5 md:text-xl md:leading-7 ${textColor}`}>
        {message}
        <a href={linkUrl} className={`${linkColor} underline`}>
          {linkText}
        </a>
      </span>
      {imgSrc1 && (
        <div className="infinity-move rounded-full overflow-hidden flex-0 ml-4">
          <Image width={imgWidth} height={imgHeight} src={imgSrc1} alt={imgAlt} />
        </div>
      )}
    </div>
  );
};

export default ComingSoonBanner;
