import PropTypes from "prop-types";

const TextNew = ({
    variant,
    fontSize,
    fontWeight,
    textColor,
    children,
    className,
    onClick,
    fontFamily,

}) => {
    const TextStyle = {
        caption: "text-xs lg:text-base",
        bodySmall: "text-sm",
        body: "text-sm lg:text-base",
    };

    const fontSizeStyle = fontSize
        ? fontSize
        : TextStyle[variant] || TextStyle.body1;

    return (
        <p
            className={`${fontSizeStyle} ${fontWeight} ${fontFamily} ${textColor} ${className}`}
            onClick={onClick}
        >
            {children}
        </p>
    );
};

TextNew.defaultProps = {
    variant: "body",
    fontSize: "",
    fontWeight: "font-normal",
    fontFamily: "font-Nunito",
    textColor: "text-grey-shade3",
    children: "",
    className: "",
    onClick: () => {},
};

TextNew.propTypes = {
    variant: PropTypes.string,
    children: PropTypes.node,
    fontSize: PropTypes.string,
    fontFamily: PropTypes.string,
    fontWeight: PropTypes.string,
    textColor: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
};

export default TextNew;