import React from "react";
import Carousel from "@molecules/Carousel";
import Heading from "@atoms/Heading";
import Container from "@atoms/Container";
import Therapist from "../molecules/Therapist";
import Image from "next/image";
import HeadingNew from "@atoms/HeadingNew";

export default function TherapistSection({ therapists }) {
    const getColorKey = (index) => {
        const colors = ['text-secondary-700', 'text-lightGreen', 'text-primary-700']; // Example colors
        return colors[index % colors.length];
    }
    return (
        <section
            className="relative text-center pt-[66px] md:pt-[105px] pb-[118px] md:pb-[188px] bg-secondary-800 overflow-hidden"
        >
            <Container type="type11">
                
                <HeadingNew
                    type="h2"
                    fontSize="text-xl lg:text-[40px]"
                    fontWeight="font-semibold "
                    className="relative leading-normal text-center pb-10 lg:pb-24 pl-2 lg:pl-6"
                >
                    Meet our Childcare Experts
                </HeadingNew>

                <Carousel
                    slidesToShow={4}
                    arrows={true}
                    autoPlay={false}
                    speed={1000}
                    arrowPosition={"topCenter"}
                    iconBg=""
                    iconSize="w-9 md:w-16 h-9 md:h-16"
                    leftArrowIcon={
                        <Image
                            src="/assets/images/sunshine/leftArrow.svg"
                            width={64}
                            height={64}
                            alt=""
                        />}
                    rightArrowIcon={
                        <Image
                            src="/assets/images/sunshine/rightArrow.svg"
                            width={64}
                            height={64}
                            alt=""
                        />}
                    prevIconClass="relative left-0"
                    nextIconClass="relative right-0"
                    responsive={[
                        {
                            breakpoint: 2000,
                            settings: {
                                slidesToShow: 4,
                                slidesToScroll: 4,
                                vertical: false,
                                arrows: true,
                                verticalSwiping: false,
                                arrowPosition: "topCenter"
                            },
                        },
                        {
                            breakpoint: 1536,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 3,
                                vertical: false,
                                arrows: true,
                                verticalSwiping: false,
                                arrowPosition: "topCenter"
                            },
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                vertical: false,
                                arrows: true,
                                verticalSwiping: false,
                                arrowPosition: "topCenter"
                            },
                        },
                        {
                            breakpoint: 480,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                arrows: true,
                                vertical: false,
                                verticalSwiping: false,
                                arrowPosition: "topCenter"
                            },
                        },
                    ]}
                >
                    {therapists?.map((therapist, i) => (
                        <Therapist
                            textColor={getColorKey(i + 3)}
                            key={therapist.id}
                            id={therapist.slug}
                            designation={therapist?.TherapistCategories.map(x => x.name).length > 0 ? therapist?.TherapistCategories.map(x => x.name)[0] : 'Psychologist'}
                            profileImg={therapist.profileImg}
                            name={therapist.name}
                        // textColor={therapist.textColor}
                        />
                    ))}
                </Carousel>
            </Container>
            <div className="absolute right-0 -bottom-2 max-w-[100px] lg:max-w-max">
                <Image
                    src="/assets/images/sunshine/vector-rabbit.svg"
                    width={192}
                    height={277}
                    alt=""
                />
            </div>
        </section>
    );
}
