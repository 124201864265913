import * as React from "react";
import Image from "next/image";
import Link from "next/link";
import Text from "@atoms/Text";
import TextNew from "@atoms/TextNew";
import HeadingNew from "@atoms/HeadingNew";

const CentersCard = ({ locationImg, location, address, direction, link, phone }) => {
    return (
        <div className=" w-[94%] lg:w-full h-full top-10 left-0 mt-10 flex md:flex-row flex-col  gap-2 justify-center mx-auto items-center">
            <div className="min-w-[196px] max-w-90 lg:mr-3 mx-auto cursor-pointer">
                <a href={link}  target="_blank" rel="noopener noreferrer">

                    <div className="relative " >
                        <Image
                            width={360}
                            height={240}
                            loading="lazy"
                            src={locationImg}
                            className="rounded-[36px] "
                        />
                        <div className="absolute bottom-5 right-5">
                            <div className="bg-white py-1.5 px-2 rounded-lg font-bold text-base font-Nunito cursor-pointer">
                                <a href={link} target="_blank" rel="noopener noreferrer">
                                    View More
                                </a>
                            </div>

                        </div>
                    </div>
                </a>

            </div>

            <div className=" flex  w-[88%] lg:max-w-[268px] flex-col justify-start items-start">
                <Text className="text-zinc-800 !text-2xl text-left  !font-bold leading-8 w-full font">

                </Text>

                <HeadingNew
                    type="h4"
                    fontSize="text-sm lg:text-2xl"
                    fontWeight="font-bold"
                    className="w-full mt-2 text-left">
                    <a href={link} target="_blank" rel="noopener noreferrer">
                        {location}
                    </a>

                </HeadingNew>
                <TextNew variant="caption" className="text-[#838383] font-medium leading-4 lg:leading-6 w-full mt-3 text-left">
                    {address}
                </TextNew>
                <TextNew variant="caption" className="w-full mt-2 text-left text-[#555555]">
                    <span className="lg:text-base text-xs font-semibold font-poppins" >Contact No:</span> {phone}
                </TextNew>

                <Link href={direction}>
                    <Text className="cursor-pointer -pt-1 text-[#838383] text-center text-[10px] lg:text-sm font-medium leading-5 flex items-center gap-1 mt-2 underline">
                        Get Directions
                        <div className="mt-1.5">
                            <Image
                                width={14}
                                height={14}
                                src="/assets/images/sunshine/direction.svg"
                            />
                        </div>
                    </Text>
                </Link>
            </div>
        </div>
    );
};

export default CentersCard;
