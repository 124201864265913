import PropTypes from "prop-types";

const   HeadingNew = ({
    children,
    type,
    className,
    fontSize,
    fontWeight,
    fontColor,
    align,
    fontFamily,
}) => {
    const headingStyles = {
        h1: "text-xl md:text-3xl lg:text-[40px]/[60px]",
        h2: "text-4xl",
        h3: "text-3xl",
        h4: "text-lg lg:text-2xl",
        h5: "text-lg lg:text-xl/[28px]",
        h6: "text-base",
    };

    const Type = headingStyles[type] ? type : "h1";
    const fontSizeStyle = fontSize ? fontSize : headingStyles[type];

    return (
        <Type
            className={`${fontSizeStyle} ${className} ${fontWeight} ${fontColor} text-${align} ${fontFamily}`}
        >
            {children}
        </Type>
    );
};

HeadingNew.defaultProps = {
    type: "h1",
    className: "",
    fontSize: "",
    fontWeight: "font-normal",
    fontColor: "text-grey-shade1",
    align: "text-left",
    fontFamily: "font-poppins",
};

HeadingNew.propTypes = {
    type: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6"]),
    className: PropTypes.string,
    fontSize: PropTypes.string,
    fontFamily: PropTypes.string,
    fontWeight: PropTypes.oneOf([
        "font-light",
        "font-normal",
        "font-medium",
        "font-semibold",
        "font-bold",
        "font-black",
        "",
    ]),
    align: PropTypes.oneOf(["left", "center", "right"]),
    children: PropTypes.node,
    fontColor: PropTypes.string,
};

export default HeadingNew;