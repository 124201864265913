import HeadingNew from "@atoms/HeadingNew";
import Text from "@atoms/Text";
import TextNew from "@atoms/TextNew";
import Image from "next/image";
import * as React from "react";

const ClientReviews = ({ imageUrl, name, content, location }) => {
    return (
        <div className="relative shadow-testimonialCard bg-[#EFE9D1] px-4 py-5 lg:p-12  rounded-[30px] lg:rounded-xl   text-left h-[270px]  lg:h-[357px]  w-11/12 mx-auto lg:flex lg:items-center">

            <div className="lg:h-[261px] lg:min-w-[220px] lg:w-1/5 flex flex-row lg:flex-col items-start lg:items-center  justify-start lg:justify-center">
                <div className="lg:block hidden">
                    <Image
                        width={200}
                        height={200}
                        loading="lazy"
                        src={imageUrl}
                        className=""
                    />
                    <HeadingNew
                        type="h4"
                        fontWeight="font-bold"
                        fontColor="text-sm leading-5 lg:leading-8 lg:text-2xl"
                        className="text-zinc-800 -mt-2 text-left lg:text-center">
                        {name}
                    </HeadingNew>
                </div>
                <div className="flex  items-center gap-2 lg:hidden block  ">
                    <div className="rounded-full overflow-hidden">                    <Image
                        width={60}
                        height={60}
                        loading="lazy"
                        src={imageUrl}
                        className="rounded-full"
                    />
                    </div>

                    <div>
                        <HeadingNew type="h4" fontSize="text-sm" fontWeight="font-bold" className="text-zinc-800 leading-8  whitespace-nowrap text-left lg:text-center">
                            {name}
                        </HeadingNew>
                        <div className="flex flex-row-reverse justify-end  gap-2 -mt-2 lg:mt-0 ">
                            <Image
                                loading="lazy"
                                width={12}
                                height={12}
                                src="/assets/images/mdi_location.svg"
                                className="aspect-square object-contain object-center w-3 mr-2"
                            />
                            <Text className="text-zinc-800 text-left text-xs md:text-sm  lg:text-base font-semibold ">
                                {location}
                            </Text>
                        </div>
                    </div>
                </div>
                <div className="hidden lg:block">
                    <div className="flex items-center lg:mt-2 gap-2 ">
                        <Image
                            loading="lazy"
                            width={20}
                            height={20}
                            src="/assets/images/mdi_location.svg"
                            className="aspect-square object-contain object-center w-5 mr-2"
                        />
                        <Text className="text-zinc-800 text-sm md:text-sm  lg:text-base font-semibold ">
                            {location}
                        </Text>
                    </div>

                </div>

            </div>

            <div className="px-0 lg:px-8">
                <TextNew
                    type="body"
                    className="text-[#232323] leading-5 lg:leading-6  mt-2 md:mt-3 xl:mt-4 line-clamp-[8] lg:line-clamp-[11] "
                    fontSize="text-xs md:text-sm lg:text-base "
                >
                    {content}
                </TextNew>
            </div>
        </div>
    );
};

export default ClientReviews;
