import { Listbox, Transition } from "@headlessui/react";
import Image from "next/image";
import React from "react";
const Select = ({
    options,
    selectedOption,
    handelChange,
    tabIndex,
    setSelectedOption,
    placeholder,
    selectBtnFontSize = "text-base",
}) => (
    <Listbox as="div" value={selectedOption} onChange={setSelectedOption}>
        {({ open }) => (
            <div className="relative select-none">
                <span className="inline-block w-full">
                    <Listbox.Button
                        tabIndex={tabIndex}
                        className="cursor-pointer w-full rounded-lg   bg-gray-100 px-3 py-3.5 text-left transition ease-in-out duration-150"
                    >
                        <span
                            className={` block truncat ${selectBtnFontSize} leading-5 ${
                                selectedOption.title
                                    ? "text-black"
                                    : "text-gray-400"
                            }`}
                        >
                            {selectedOption.title
                                ? selectedOption.title
                                : placeholder || "Select From DropDown"}
                        </span>
                        <span className="absolute inset-y-0 right-0 flex items-center pr-5 ml-3">
                            {open ? (
                                <Image
                                    src="/assets/images/downArrow111.svg"
                                    alt="arrowdown"
                                    width="14"
                                    height="14"
                                    className="rotate-180 cursor-pointer"
                                />
                            ) : (
                                <Image
                                    src="/assets/images/downArrow111.svg"
                                    alt="arrowup"
                                    width="14"
                                    height="14"
                                    className="cursor-pointer"
                                />
                            )}
                        </span>
                    </Listbox.Button>
                </span>
                <div className="w-full absolute z-10 mt-1 bg-white rounded-md shadow-lg mb-11">
                    <Transition
                        show={open}
                        leave="transition duration-100 ease-in"
                        leaveFrom="transform opacity-100"
                        leaveTo="transform opacity-0"
                    >
                        <Listbox.Options
                            static
                            className="py-1 overflow-auto text-base rounded-lg max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                        >
                            {options.map((option) => (
                                <Listbox.Option
                                    as={React.Fragment}
                                    key={option.id}
                                    value={option}
                                    hidden={
                                        option.title ===
                                        "Select Run Of Show Template"
                                    }
                                >
                                    {({ active, selected }) => (
                                        <li
                                            className={`${
                                                active
                                                    ? "text-gray-500 "
                                                    : "text-gray-400"
                                            } border-focused text-base leading-5 cursor-pointer select-none  hover:bg-gray-100  hover:text-black relative py-2 pl-3 pr-9 `}
                                        >
                                            <div className="flex items-center">
                                                <span
                                                    className={
                                                        "text-base leading-5 flex items-center truncate"
                                                    }
                                                >
                                                    {option.title}
                                                </span>
                                                {selected && (
                                                    <span
                                                        className={`${
                                                            active
                                                                ? "text-gray-500 "
                                                                : "text-gray-400"
                                                        } text-base font-semibold leading-5 absolute inset-y-0 right-0 flex items-center mr-3 pl-1.5`}
                                                    ></span>
                                                )}
                                            </div>
                                        </li>
                                    )}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </div>
            </div>
        )}
    </Listbox>
);
export default Select;
